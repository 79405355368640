import React from "react"
import MainTemplate from "templates/Main/MainTemplate";
import RegisterScreen from "components/Pages/RegisterScreen";

const RegisterPage = (props) => (
    <MainTemplate {...props}>
        <RegisterScreen {...props}/>
    </MainTemplate>
);

export default RegisterPage;