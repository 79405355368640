import React from "react"
import styled from "styled-components"
import SectionHeader from "../SectionHeader"
import { graphql, StaticQuery } from "gatsby"
import { translate } from "services/translate"
import Button from "components/Button"
import TopBar from "components/TopBar"
import { navigate } from "gatsby-link"
import axios from "axios"
import Loader from "components/Loader"
import AutocompleteInput from "components/Forms/AutocompleteInput"
import CheckboxInput from "components/Forms/CheckboxInput"
import "assets/styles/form-details.css"

import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form"
import TextInput from "components/Forms/TextInput"
import { navigateWithParam } from "../../services/utils"
import ExternalAuth from "../ExternalAuth";
import InstallPopup from "../InstallPopup";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px 15px 90px 15px ;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  overflow: hidden;

  &:before {
    background: -webkit-linear-gradient(bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,0.3) 100%);
    background: url(${({ background }) => (background ? background : "")})
      no-repeat center top fixed;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    filter: blur(8px);
    transform: scale(1.1);
  }
`

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;

  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const Hint = styled.div`
    margin: 2rem 0;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: relative;
`

class RegisterScreen extends React.Component {
    registerForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        restaurant: ["", Validators.required],
        recommendedCode: [""],
        terms: [false, Validators.requiredTrue],
        terms2: [false, Validators.requiredTrue],
        terms3: [false, Validators.requiredTrue],
        terms4: [false, Validators.requiredTrue],
    })

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: "",
            barList: [],
        }
    }

    handleAutocompleteChange = (value) => {
        this.registerForm.setValue({
            ...this.registerForm.value,
            restaurant: value,
        })

        setTimeout(() => {
            let params = {
                "filter[name][like]": value,
            }

            axios
                .get(`${process.env.API_URL}/bars`, { params })
                .then(barList => {
                    this.setState({
                        barList: barList.data,
                    }, () => {
                        this.forceUpdate()
                    })
                })
                .catch(error => {
                    this.setState({ loading: false, error: error })
                })
        })
    }

    handleSubmit = event => {
        event.preventDefault()

        const self = this

        const firebase = require("firebase")
        const database = firebase.firestore()

        firebase.auth().useDeviceLanguage()

        this.setState({
            loading: true,
        })

        let params = {
            "access-token": process.env.MASTER_TOKEN,
            "expand": "saldoHistory,questionnaires,address,contestToUser.contest,welcomepack",
        }

        axios
            .post(`${process.env.API_URL}/profiles`, {
                email: this.registerForm.value.email,
                password: this.registerForm.value.password,
                firstName: this.registerForm.value.firstName,
                lastName: this.registerForm.value.lastName,
                restaurant: this.registerForm.value.restaurant,
                recommendedCode: this.registerForm.value.recommendedCode,
            }, { params })
            .then(response => {
                firebase.auth().createUserWithEmailAndPassword(this.registerForm.value.email, this.registerForm.value.password)
                    .then((result) => {
                        let loginMethod = false

                        if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
                            loginMethod = "?firstLoginEmail"
                        }

                        sessionStorage.setItem("firstUser", loginMethod)

                        var user = firebase.auth().currentUser

                        database.collection("users").doc(user.uid).set({
                            userId: response.data.userId,
                            profileId: response.data.profileId,
                            accessToken: response.data.accessToken,
                        })
                            .then(function() {
                                self.setState({
                                    loading: false,
                                }, () => {
                                    console.log("Document successfully written!")
                                    window.setTimeout(() => {
                                        let firstUser = sessionStorage.getItem("firstUser")
                                        if (firstUser === "false") {
                                            navigate(`/app/dashboard/`)
                                        } else {
                                            navigateWithParam("/app/dashboard/", firstUser)
                                        }
                                    }, 300)
                                })
                            })
                            .catch(function(error) {
                                self.setState({
                                    loading: false,
                                    error: {
                                        code: error.code,
                                        message: translate(error.code),
                                    },
                                })

                                // Delete user from Firebase, because of error in firestore
                                user.delete().then(function() {
                                    alert("Coś poszło nie tak, przepraszamy! Spróbuj ponownie!")
                                }).catch(function(error) {
                                    self.setState({
                                        loading: false,
                                        error: {
                                            code: error.code,
                                            message: translate(error.code),
                                        },
                                    })
                                })
                            })
                    })
                    .catch(error => {
                        this.setState({
                            error: {
                                code: error.code,
                                message: translate(error.code),
                            },
                            loading: false,
                        })
                    })
            })
            .catch(error => {
                this.setState({
                    error: error.response ? error.response.data[0] : error,
                    loading: false,
                })
            })
    }

    handleStateWithExternalAuth = (newState) => {
        this.setState(newState);
    };

    render() {
        let { loading, error } = this.state

        return (
            <StaticQuery
                query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "login.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                render={data => (
                    <Wrapper background={data.loginBackground.childImageSharp.fluid.src}>
                        {loading &&
                        <Loader/>
                        }

                        <TopBar link="/login" fixed="true"/>
                        <SectionHeader header="Zarejestruj się" center="true"/>

                        <ExternalAuth onLoadFunc={this.handleStateWithExternalAuth} register={true} />

                        <FormWrapper>
                            <FieldGroup
                                control={this.registerForm}
                                render={({ get, invalid }) => (
                                    <form onSubmit={this.handleSubmit}>

                                        <FieldControl
                                            name="firstName"
                                            render={TextInput}
                                            meta={{ label: "Imię", style: "bordered" }}
                                        />

                                        <FieldControl
                                            name="lastName"
                                            render={TextInput}
                                            meta={{ label: "Nazwisko", style: "bordered" }}
                                        />

                                        <FieldControl
                                            name="email"
                                            render={TextInput}
                                            meta={{ label: "Adres e-mail", style: "bordered" }}
                                        />

                                        <FieldControl
                                            name="password"
                                            render={TextInput}
                                            meta={{
                                                label: "Hasło",
                                                type: "password",
                                                style: "bordered",
                                                minLength: 8,
                                            }}
                                        />

                                        <AutocompleteInput value={this.registerForm.value.restaurant}
                                                           placeholder="Nazwa baru"
                                                           onChange={this.handleAutocompleteChange}
                                                           options={this.state.barList}
                                        />

                                        <FieldControl
                                            name="recommendedCode"
                                            render={TextInput}
                                            meta={{ label: "Kod weryfikacyjny - nieobowiązkowy", style: "bordered", placeholdersmaller: true }}
                                        />

                                        {/*Hidden input - autocomplete managed*/}
                                        <FieldControl
                                            name="restaurant"
                                            render={TextInput}
                                            meta={{ label: "Nazwa baru", style: "bordered", hidden: true }}
                                        />

                                        <FieldControl
                                            name="terms"
                                            render={CheckboxInput}
                                            meta={{
                                                label: "Osoba pełnoletnia",
                                                content:
                                                    <details className="form-agrrment">
                                                        <summary className="form-agrrment__title">
                                                            Oświadczam, że jestem osobą
                                                        </summary>
                                                        <div className="form-agrrment__text">
                                                            pełnoletnią wykonującą zawód barmana w lokalu na terenie
                                                            Rzeczypospolitej Polskiej.
                                                        </div>
                                                    </details>,
                                                style: "bordered",
                                            }}
                                        />

                                        <FieldControl
                                            name="terms2"
                                            render={CheckboxInput}
                                            meta={{
                                                label: "Regulamin",
                                                content:
                                                    <details className="form-agrrment">
                                                        <summary className="form-agrrment__title">
                                                            Oświadczam, że zapoznałam/em się
                                                        </summary>
                                                        <div className="form-agrrment__text">
                                                            z <a href="https://barisus.pl/pdf/regulamin.pdf"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer">Regulaminem</a> i zobowiązuję
                                                            się do przestrzegania zawartych w nim postanowień.
                                                        </div>
                                                    </details>,
                                                style: "bordered",
                                            }}
                                        />

                                        <FieldControl
                                            name="terms3"
                                            render={CheckboxInput}
                                            meta={{
                                                label: "Polityka prywatności",
                                                content:
                                                    <details className="form-agrrment">
                                                        <summary className="form-agrrment__title">
                                                            Oświadczam, że zapoznałam/em się
                                                        </summary>
                                                        <div className="form-agrrment__text">
                                                            z zasadami przetwarzania moich danych osobowych zawartymi
                                                            w <a
                                                            href="https://barisus.pl/pdf/polityka_prywatnosci_i_cookies.pdf"
                                                            target="_blank" rel="noopener noreferrer">Polityce
                                                            Prywatności</a>.
                                                        </div>
                                                    </details>,
                                                style: "bordered",
                                            }}
                                        />

                                        <FieldControl
                                            name="terms4"
                                            render={CheckboxInput}
                                            meta={{
                                                label: "Weryfikacja",
                                                content:
                                                    <details className="form-agrrment">
                                                        <summary className="form-agrrment__title">
                                                            Jestem świadoma/y, że aktywacja
                                                        </summary>
                                                        <div className="form-agrrment__text">
                                                            mojego konta w aplikacji BAR IS US nastąpi po pozytywnej
                                                            weryfikacji wykonywania przeze mnie zawodu barmana we
                                                            wskazanym powyżej lokalu gastronomicznym oraz weryfikacji
                                                            zgody właściciela tego lokalu na korzystanie przeze mnie z
                                                            Aplikacji.
                                                            <br/>
                                                            <br/>
                                                            Rozumiem, że o ile nie zostanę poinformowana/y w wiadomości
                                                            e-mail o uprzednim wyrażeniu przez właściciela lokalu zgody
                                                            w tym zakresie, zgodę tę powinnam/powinienem uzyskać we
                                                            własnym zakresie, posługując się formularzem zamieszczonym
                                                            pod poniższym odnośnikiem, a następnie przesłać skan
                                                            uzupełnionego i podpisanego formularza na adres: <a
                                                            href="mailto:team@barisus.pl">team@barisus.pl</a>.
                                                            <br/>
                                                            <br/>
                                                        </div>
                                                    </details>,
                                                style: "bordered",
                                            }}
                                        />

                                        <details className="form-agrrment rodo-info">
                                            <summary className="form-agrrment__title">
                                                Klauzula informacyjna
                                            </summary>
                                            <div className="form-agrrment__text">
                                                Administratorem Pani/Pana danych osobowych jest Stock Polska sp. z o.o.
                                                z siedzibą w Lublinie, przy ul. Spółdzielczej 6, 20-402 Lublin. Podane
                                                przez Panią/Pana dane osobowe będą przetwarzane w celach związanych z
                                                funkcjonalnością Aplikacji, których szczegółowy zakres znajduje się w
                                                Polityce Prywatności. <a
                                                href="https://barisus.pl/pdf/polityka_prywatnosci_i_cookies.pdf"
                                                target="_blank" rel="noopener noreferrer">Więcej informacji o
                                                przetwarzaniu danych
                                                osobowych</a>.
                                            </div>
                                        </details>

                                        <Button
                                            type="submit"
                                            disabled={invalid}
                                            title="Zarejestruj się"
                                        />
                                    </form>
                                )}
                            />

                            {error &&
                            <div className="error">
                                <div>{error.message}</div>
                            </div>
                            }
                        </FormWrapper>

                        <InstallPopup />

                    </Wrapper>
                )}
            />
        )
    }
}

export default RegisterScreen
